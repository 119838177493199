import React, { useReducer, useState } from 'react';
import './register.scss';
import Header from '../Header';
import Footer from '../Footer';
import wingmanImage from '../../Assets/Images/wingmanImage.webp';
import { db, storage } from '../../Database/firebase';
import { ref as storageRef, getDownloadURL, uploadBytes } from "firebase/storage";
import { addDoc, collection, Timestamp } from 'firebase/firestore';

const chReducer = (state, payload) => ({ ...state, ...payload });

const Matchmaker = () => {
  const [state, dispatch] = useReducer(chReducer, {
    phnNumber: "",
    email: "",
    linkedin: "",
    description: "",
    price: "",
    photoID: "",
    pictures: [],
    review: ""
  })
  const [, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const validate = values => {
    let errors = {};
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email is invalid";
    }
    return errors;
  };

  //Upload each picture to Firebase Storage and get the download URL for each picture
  const uploadPicture = async (picture) => {
    const fileName = `${picture.name}`;
    const pictureRef = await storageRef(storage, fileName);
    await uploadBytes(pictureRef, picture).then((snapshot) => {
    }).catch(error => {
      console.log("uploadBytes", error);
    })
    return getDownloadURL(pictureRef).then((url) => {
      return url
    }).catch((error) => {
      console.log("getDownloadURL", error);
    });
  };


  const handleUploadPictures = (pictures) => {
    return Promise.all(pictures.map(picture => uploadPicture(picture)))
      .then(urls => {
        return urls
      });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setErrors(validate(state));
    if (Object.keys(validate(state)).length === 0) {
      const pictureUrls = await handleUploadPictures(state.pictures);
      const photoIDUrl = await uploadPicture(state.photoID)
      await addDoc(collection(db, 'matchmaker'), {
        number: state.phnNumber,
        email: state.email,
        linkedin: state.linkedin,
        description: state.description,
        price: state.price,
        photoID: photoIDUrl,
        pictures: pictureUrls,
        review: state.review,
        created: Timestamp.now()
      }).then((res) => {
        dispatch({
          phnNumber: "",
          email: "test@yopmail.com",
          linkedin: "",
          photoID: "",
          description: "",
          price: "",
          pictures: [],
          review: ""
        })
        alert("Submitted Successfully !!!!")
      }).catch((error) => {
        console.log("There was an error:", error)
      });
    }
    else {
      console.log("error");
      setIsSubmitting(false);
    }
  };

  const handleRemoveChange = (index) => {
    const pictures = state.pictures.filter((_, i) => i !== index);
    dispatch({ pictures: pictures });
  }
  const handleNumberChange = (e) => {
    if (!isNaN(+e.target.value)) dispatch({ [e.target.name]: e.target.value })
  }
  
  return (
    <>
      <Header />
      <div className='wingman'>
        <div className='main-sect-1'>
          <div className='main-lex-sec-1'>
            <div className='flex-left-sec-1'>
              <h2>Apply to be a match maker</h2>
              <p className='p-1'>BECOME A MATCH MAKER AND MAKE FRIENDS IN THE CITY</p>
              <h5 className="guide_points">GET NEW CLIENTS</h5>
              <h5 className="guide_points">MEET NEW PEOPLE</h5>
              <h5 className="guide_points">EARN SOME POCKET MONEY</h5>
            </div>
            <div className='flex-right-sec-1'>
              <img src={wingmanImage} alt="wingman" className='matchmacker_img' />
            </div>
          </div>
        </div>
        <div className='form'>
          <h2 className='form_h2'>Apply to be a match maker</h2>
          <form onSubmit={handleSubmit}>
            <fieldset>
              <label for="phnNumber">Phone Number:</label>
              <input type="text" name='phnNumber' id='phnNumber' value={state.phnNumber} onChange={(e) => handleNumberChange(e)} />
              {errors.phnNumber && <p className='error'>{errors.phnNumber}</p>}
            </fieldset>

            <fieldset>
              <label for="email">Contact Email:</label>
              <input type="email" name='email' id='email' value={state.email} onChange={(e) => dispatch({ email: e.target.value })} />
              {errors.email && <p className='error'>{errors.email}</p>}
            </fieldset>

            <fieldset>
              <label for="linkedin">Linkedin:</label>
              <input type="text" value={state.linkedin} id="linkedin" onChange={(e) => dispatch({ linkedin: e.target.value })} />
            </fieldset>
            <fieldset>
              <label for="description">Description:</label>
              <textarea type="text" value={state.description} id="description" onChange={(e) => dispatch({ description: e.target.value })} />
            </fieldset>
            <fieldset>
              <label for="price">Price:</label>
              <input type="text" value={state.price} id="price" name='price' onChange={(e) => handleNumberChange(e)} />
            </fieldset>

            <fieldset>
              <label for="photoID">Photo ID:</label>
              <input type="file" accept="image/*" id='photoID' onChange={(e) => dispatch({ photoID: e.target.files[0] })} />
            </fieldset>

            <fieldset>
              <label for="pictures">Pictures of themselves or video of themselves:</label>
              <input type="file" className='multi-file' id='pictures' accept="image/* , video/*" onChange={(e) => dispatch({ pictures: Array.from(e.target.files) })} multiple />
              <div className="upload__img-wrap">
                {state.pictures.map((file, i) => {
                  return <div className='upload__img-box' key={i}>
                    {
                      file.type.includes("image")
                        ? <img src={URL.createObjectURL(file)} alt="img" />
                        : <video controls >
                          <source src={URL.createObjectURL(file)} />
                        </video>
                    }
                    <div className="upload__img-close" onClick={() => handleRemoveChange(i)}></div>
                  </div>
                })}</div>
            </fieldset>

            <fieldset>
              <label for="review">Website</label>
              <input type="text" value={state.review} id="review" onChange={(e) => dispatch({ review: e.target.value })} />
            </fieldset>
            <div className='form_btn'>
              <button type="submit" className='btn_form'>SUBMIT APPLICATION</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Matchmaker;
