import React,{ useState } from 'react';
import './FAQs.scss';

const FAQs = ({faqs}) => {
  const [selectFAQ, setSelectFAQ] = useState(faqs[0]);

  const faqSelected = (faq) => {
    setSelectFAQ(faq);
  };

  return (  
      <div className="faqs">
        <h1>FAQ</h1>
        <div className='row'>
          <div className='col-md-6 faqs-title'>
            {faqs.map((faq, index) => {
              return (
                <div className={`${index === selectFAQ.id ? "faq-title-active" : ""} faq-title`} key={index} onClick={() => faqSelected(faq)}>
                  {faq.title}
                </div>
              )
            })}
          </div>

          <div className="col-md-6 faqs-ans">
            <h2>{selectFAQ.title}</h2>
            <div className='ans-faq'>{selectFAQ.description1}</div>
            <br />
            <div>{selectFAQ.description2 ? selectFAQ.description2 : ''}</div>
          </div>
        </div>
      </div>
  )
}

export default FAQs;