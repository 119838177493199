import './eula.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet-async';

const EULA = () => {
      const url = `${window.location.origin}${window.location.pathname}`;

      return (
            <>
            <Helmet>
                        <title>{"Eula"}</title>
                        <link rel="canonical" href={url} />
                  </Helmet>
                  <Header />
                  <div className='eula'>
                        <h2>END USER LICENSE AGREEMENT</h2>
                        <p>THIS END USER LICENSE AGREEMENT (“LICENSE AGREEMENT”) IS A LEGAL AGREEMENT. READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE ACCESSING OR USING ANY Kupenda PRODUCT (the “PRODUCT”). THE PRODUCT CONTAINS EMBEDDED SOFTWARE (“SOFTWARE”), THE ACCESS AND USE OF WHICH IS LICENSED BY Kupenda TO ITS CUSTOMERS FOR THEIR USE ONLY AS SET FORTH BELOW. IF YOU DO NOT ACCEPT THE TERMS AND CONDITIONS OF THIS LICENSE AGREEMENT, DO NOT CREATE A Kupenda ACCOUNT AND DO NOT USE THE PRODUCT. BY CREATING A Kupenda ACCOUNT OR ACCESSING OR USING THE PRODUCT, YOU INDICATE THAT YOU ACCEPT AND AGREE TO THESE TERMS. THE PRODUCT LIMITATIONS SECTION BELOW SPECIFIES IMPORTANT LIMITATIONS OF THE SOFTWARE AND RELATED SERVICES IN CONNECTION WITH LIFE SAFETY AND CRITICAL USES. PLEASE READ THIS SECTION CAREFULLY, AS YOU ARE ACKNOWLEDGING AND AGREEING TO ITS TERMS.</p>

                        <h2>CONTRACTUAL RELATIONSHIP</h2>
                        <p>This Agreement is an agreement between you and Kupenda Inc. You represent and warrant that you have the capacity to enter into this License Agreement relating to the Software and are of sufficient legal age in your jurisdiction or residence to use or access the Software.These terms give you specific legal rights, and you may also have other legal rights in addition, which vary from jurisdiction to jurisdiction.</p>

                        <h2>USER GENERATED CONTENT</h2>
                        <p>You are personally responsible for your use of the Product and Software, and while using the Product and Software you must conduct yourself in a lawful and respectful manner in
                              accordance with our rules of conduct below. We may temporarily or permanently ban users who violate these rules, or who abuse communications, support communications, or the community purpose of any message board areas, as determined by us and our developer partners, in our sole discretion.</p>
                        <p>Profanity, obscenities, or the use of <b>asterisks</b> or other “masking” characters to disguise such words, is not permitted.</p>
                        <p>You acknowledge that the Service is a passive conduit for user content and that: (i) neither we, nor our developer partners, pre-screen user content or communications or (ii) control, verify or pay for any user content or communications. We do not endorse, and specifically disclaim any responsibility or liability for, any publicly posted content. In addition, as agent for our developer partners, we may terminate your access to any public forums at any time, without notice, for any reason whatsoever, and/or delete, move or edit content submitted publicly, in whole or in part. You may only upload, send, and receive messages and material that is related to the subject matter of the public forums, complies with Applicable Laws, and conforms to any additional terms of service posted in the public forums. You may not upload to, distribute, or otherwise publish any content, information, or other material that (a) violates or infringes the copyrights, patents, trademarks, service marks, trade secrets, or other proprietary rights of any person; (b) is libelous, threatening, defamatory, obscene, indecent, pornographic, or could give rise to any civil or criminal liability under U.S. or international law; or (c) includes any bugs, viruses, worms, trap doors, Trojan horses or other harmful code or properties. Submissions or opinions expressed by users are that of the individual expressing such submission or opinion only. Subject to the foregoing, as agent for our developer partners, we may edit, refuse to post, or to remove any information or materials submitted, in our discretion. You may not use a false email address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of your submissions or content.</p>

                        <h2>USER GENERATED CONTENT SAFETY</h2>
                        <p>You acknowledge that Kupenda takes lewd, abusive and obscene content seriously and has built robust business practices to address this issue. On the Kupenda platform there is no tolerance for objectionable content or abusive users. We use a service called WebPurify to screen obscene language and images, and those posts and content with objectionable content including defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups, particularly to humiliate, intimidate, or harm a targeted individual or group are removed automatically. This is our method of filtering objectionable content.</p>
                        <p>We address all objectionable content within 24 hours. Additionally, users are able to report other users from within the app for any reason with a flagging mechanism, and we are able to ban a user from the system or certain features as needed. After a post has been flagged twice the content is immediately removed and placed in quarantine for Kupenda admin review.</p>
                        <p>Users also have the ability to mute other users. This is a mechanism for users to block abusive users. As such, there isn't a way for users to continuously abuse, harm, harass or pressure one another. If a user reports some other abusive behavior, our team will remove that questionable and abusive user within 24 hours.</p>

                        <h2>LICENSE</h2>
                        <p>Subject to the terms and restrictions set forth in this License Agreement, Kupenda grants you a limited, non-exclusive license to use the Software, only in object code form, solely as embedded on the Kupenda product which you own or control, and only for and in connection with your personal, non-commercial use of the Product. You are not permitted to (and will not permit others to) lease, rent, distribute, assign, commercially exploit, or sub-license the Software or to use the Software in a time-sharing arrangement or in any other unauthorized manner. Further,
                              no license is granted to you in the human readable code of the Software (source code). The Software and accompanying documentation may be copied only as essential for backup or archive purposes in support of your use of the Software as permitted hereunder. You must reproduce and include all copyright notices and any other proprietary rights notices appearing on the Software on any copies that you make.</p>

                        <h2>OTHER SOFTWARE</h2>
                        <p>This License Agreement does not apply to any open-source software which is included in the Product or to any third-party software that is licensed separately under the terms of various separate license agreements (“Other Software”). The Other Software is not subject to the terms and conditions of this License Agreement and is instead licensed to you under the terms of the applicable third-party license agreements (the “Other Software Terms”). Copyrights to the Other Software are held by copyright holders indicated in the Other Software Terms. To the extent required by the Other Software Terms, Kupenda will make the Product’s open-source software, and any modifications made by Kupenda, available either on our website or by written request to Kupenda at the email address listed below. Any terms of this License Agreement that conflict with the terms of any license agreements for Other Software will not apply to the Other Software. Nothing in this License Agreement limits your rights under, or grants you rights that supersede, the terms and conditions of any applicable end user license for the Other Software.</p>

                        <h2>SOFTWARE UPDATES</h2>
                        <p>Kupenda may provide updates, upgrades, patches, bug fixes, and other modifications to improve the Software and related services (“Patches”). You acknowledge that you may be required to install Patches to continue to access and use the Product and the Product Software. You agree and consent to Patches being automatically installed without receiving any additional notice or providing any additional consent.</p>

                        <h2>NO ASSIGNMENT; NO REVERSE ENGINEERING</h2>
                        <p>Modification, reverse engineering, reverse compiling, or disassembly of the Software is expressly prohibited.</p>

                        <h2>EXPORT RESTRICTIONS</h2>
                        <p>The Software and Product are subject to US export control laws and may be subject to export or import regulations in other counties. You agree not to export or re-export the Software or accompanying documentation (or any copies thereof) or any products utilizing the Software or such documentation in violation of any applicable laws or regulations of the United States or the country in which you obtained them. The software covered by this License Agreement may contain strong data encryption code which cannot be exported outside of the US or Canada. You agree that you will not export/re-export, either physically or electronically, the encryption software or accompanying documentation without obtaining written authorization from the US Department of Commerce. You hereby agree to indemnify and hold Kupenda harmless from all claims, losses, liabilities, damages, fines, penalties, costs, and expenses (including attorneys’ fees) arising out of or resulting from your breach of this paragraph.</p>

                        <h2>TITLE; TRADE SECRETS</h2>
                        <p>You acknowledge and agree that all (a) worldwide copyrights and other intellectual property rights and (b) ownership of and title to the Software and all subsequent copies thereof, regardless of the form or media, are held by and the exclusive property of Kupenda and its suppliers. You further acknowledge and agree that the Software contains the valuable trade secrets and proprietary information of Kupenda and its suppliers. You agree to hold such trade secrets and proprietary information in confidence, and you acknowledge that any actual or threatened breach of this obligation will constitute immediate, irreparable harm for which monetary damages would be an inadequate remedy and that injunctive relief is an appropriate remedy for such breach.</p>

                        <h2>NO IMPLIED RIGHTS</h2>
                        <p>All rights not expressly granted by Kupenda to you in this License Agreement are hereby reserved by Kupenda and its suppliers. There are no implied rights in this License Agreement.</p>

                        <h2>UNITED STATES GOVERNMENT LEGEND</h2>
                        <p>The Software is commercial in nature and developed solely at private expense. The Software is delivered as “Commercial Computer Software” as defined in DFARS 252.227-7014 (June 1995) or as a commercial item as defined in FAR 2.101(a) and as such is provided with only such rights as are provided in this License Agreement, which is Kupenda’s standard commercial license for the Software. Technical data is provided with limited rights only as provided in DFAR 252.227-7015 (November 1995) or FAR 52.227-14 (June 1987), whichever is applicable.</p>

                        <h2>TERM AND TERMINATION</h2>
                        <p>This License Agreement is effective from the date you first use the Software or Product and shall continue until terminated. You may terminate it at any time upon written notice to Kupenda. It will also terminate immediately if you fail to comply with any term of this License Agreement. Upon such termination, the licenses granted by this License Agreement will immediately terminate, and you agree to stop all access and use of the Product, Software, and documentation. The provisions that by their nature continue and survive will survive any termination of this License Agreement.</p>

                        <h2>GOVERNING LAW & JURISDICTION</h2>
                        <p>The courts in some countries will not apply Delaware law to some types of disputes. If you reside in one of those countries where Delaware law is excluded from applying, your country’s laws will apply to such disputes related to these terms. Otherwise, you agree that the laws of the
                              State of Delaware and the United States govern this License Agreement, as well as any claim, dispute, action, or issue that might arise out of or in connection with it, excluding their conflicts of laws principles. Any action or proceeding relating to this License must be brought in a federal or state court located in New York, and each party irrevocably submits to the jurisdiction and venue of any such court in any such claim or dispute, except that Kupenda may seek injunctive relief in any court having jurisdiction to protect its confidential information or intellectual property. The United Nations Convention on Contracts for the International Sale of Goods (1980) is hereby excluded in its entirety from application to this License Agreement.</p>

                        <h2>NO ASSIGNMENT</h2>
                        <p>You may not assign or transfer your rights under this License Agreement. Any purported assignment or transfer in violation of this paragraph is void.</p>

                        <h2>LIMITED WARRANTY</h2>
                        <p>The limited warranty for the Product is as stated on Kupenda's website or on the Limited Warranty in the user manual accompanying the Product. NO WARRANTY IS PROVIDED FOR THE SOFTWARE.</p>

                        <h2>WARRANTY DISCLAIMER</h2>
                        <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Kupenda PROVIDES THE SOFTWARE “AS-IS” AND DISCLAIMS AND EXCLUDES ALL WARRANTIES AND CONDITIONS, WHETHER STATUTORY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. Kupenda DOES NOT GUARANTEE ANY SPECIFIC RESULTS FROM THE USE OF THE SOFTWARE. Kupenda MAKES NO WARRANTY THAT THE SOFTWARE WILL BE
                              UNINTERRUPTED, FREE OF VIRUSES OR OTHER HARMFUL CODE, TIMELY, SECURE, OR ERROR-FREE.</p>

                        <h2>PRODUCT LIMITATIONS</h2>
                        <p>YOUR USE OF THE SOFTWARE AND THE PRODUCT IS AT YOUR OWN DISCRETION AND RISK. YOU ARE SOLELY RESPONSIBLE FOR (AND Kupenda DISCLAIMS) ANY AND ALL LOSS, LIABILITY, OR DAMAGES ARISING OUT OF OR RESULTING FROM YOUR USE OF THE SOFTWARE OR THE PRODUCT, INCLUDING, BUT NOT LIMITED TO, ANY LOSS, LIABILITY, OR DAMAGES TO YOUR PERSON, POSSESSIONS, PERSONAL PROPERTY (INCLUDING, WITHOUT LIMITATION, THE PRODUCT, OTHER PRODUCTS, OR PERIPHERALS CONNECTED TO THE PRODUCT, COMPUTER, AND MOBILE DEVICE), DATA, HOME, APARTMENT, UNIT, DWELLING, STRUCTURE, OR RESIDENCE. Kupenda’s customer support contacts cannot be regarded as a lifesaving solution for people at risk in the home, and are not a suitable substitute for emergency services. All life-threatening and emergency events should be directed to 911 or the appropriate response services. The software app for the Product may allow you to alert emergency response services if you have subscribed to monitoring and are located in a supported area.</p>

                        <h2>NOT A REPLACEMENT FOR 911</h2>
                        <p>The Application and customer service are not a replacement for 911 or any other public emergency Response Services. If you are in immediate danger, are suffering a medical emergency, or are the victim of a crime, call 911 and/or the appropriate authorities, and do not rely on the Application or the Response Services.</p>

                        <h2>WIRELESS NETWORK CONNECTIVITY AND COVERAGE</h2>
                        <p>The Application and Response Services require adequate Wi-Fi and/or Cellular connectivity (e.g., 2G, 3G, 4G, LTE or other). When your Mobile Device does not have adequate
                              connectivity, the Application and Response Services may not work. You MUST ensure that your Mobile Device has adequate wireless network connectivity and coverage.</p>

                        <h2>LIMITATION OF LIABILITY</h2>
                        <p>Nothing in this License Agreement (including anything specified in this "Limitation of Liability" provision) shall attempt to exclude or limit liability that cannot be excluded or limited under applicable law. TO THE FULL EXTENT ALLOWED BY LAW, IN ADDITION TO THE ABOVE WARRANTY DISCLAIMERS, Kupenda DISCLAIMS AND EXCLUDES FOR ITSELF AND ITS SUPPLIERS ANY LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE, FOR INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES OF ANY KIND OR FOR LOSS OF REVENUE OR PROFITS, LOSS OF BUSINESS, LOSS OF INFORMATION OR DATA, OR OTHER LOSS ARISING OUT OF OR IN CONNECTION WITH THE SALE, INSTALLATION, MAINTENANCE, USE, PERFORMANCE, FAILURE, OR INTERRUPTION OF THE SOFTWARE OR PRODUCT, EVEN IF Kupenda OR ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND LIMITS ITS LIABILITY TO REPLACEMENT, REPAIR, OR REFUND OF THE PURCHASE PRICE PAID, AT Kupenda’S OPTION. THIS DISCLAIMER OF LIABILITY FOR DAMAGES WILL NOT BE AFFECTED IF ANY REMEDY PROVIDED HEREIN FAILS ITS ESSENTIAL PURPOSE.</p>

                        <h2>DISCLAIMER</h2>
                        <p>In addition to this License Agreement, you may also have other legal rights that vary from jurisdiction to jurisdiction. Some countries, states, or provinces do not allow the exclusion or limitation of implied warranties or the limitation of incidental or consequential damages for certain products supplied to consumers or the limitation of liability for personal injury, so the above limitations and exclusions may be limited in their application to you. When the implied warranties are not allowed to be excluded in their entirety, they will be limited to the extent and
                              duration of the applicable written warranty. Your specific legal rights may vary depending on local law.</p>

                        <h2>SEVERABILITY</h2>
                        <p>In the event any provision of this License Agreement is found to be invalid, illegal, or unenforceable, the validity, legality, and enforceability of any of the remaining provisions shall not in any way be affected or impaired, and a valid, legal, and enforceable provision of similar intent and economic impact shall be substituted therefor.</p>

                        <h2>NOTICES</h2>
                        <p>Kupenda may send notices to you via email or to a mailing address. Notices will be sent to the address that you registered with Kupenda.</p>

                        <h2>WAIVERS</h2>
                        <p>All waivers by Kupenda will be effective only if in writing. Any waiver or failure by Kupenda to enforce any provision of this License Agreement on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.</p>

                        <h2>CAPTIONS</h2>
                        <p>The captions and paragraph headings of this License Agreement are for convenience and are not to be used in interpreting this License Agreement.</p>

                        <h2>ENTIRE AGREEMENT & AMENDMENT</h2>
                        <p>This License Agreement sets forth the entire understanding and agreement between you and Kupenda, supersedes all prior agreements, whether written or oral, with respect to the Software, and may be amended only in a writing signed by both parties that identifies itself as an amendment to this License Agreement.</p>

                        <h2>QUESTIONS OR ADDITIONAL INFORMATION</h2>
                        <p>If you have questions regarding this License Agreement, please contact Kupenda by sending an email to <a href='mailto:info@Kupenda.io'>info@Kupenda.io.</a></p>
                  </div>
                  <Footer />
            </>
      );
}

export default EULA;
