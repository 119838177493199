import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import footerImg from "../../Assets/Header/kupenda-logo.webp";
import faceboookImg from "../../Assets/Images/facebook.png";
import twitterImg from "../../Assets/Images/twitter.png";

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer_wrp'>
        <div className='footer-contain'>
          <img src={window && window.innerWidth >= 767 ? '../../../footer_logo.png' : footerImg}
            alt='footer' />
          <div>
            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
              <img src={faceboookImg} alt="facebook" className='facebook-img' />
            </a>
            <a href="https://twitter.com/" target="_blank" rel="noreferrer">
              <img src={twitterImg} alt="twitter" className='twitter-img' />
            </a>
          </div>
          <div className='footer_wrpend'>
            <Link to="/terms-conditions" className='footer-text'>
              Terms of use
            </Link>
            <Link to="/privacy" className='footer-text'>
              Privacy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
