import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Privacy from "./Pages/Privacy";
import Wingman from "./Pages/Wingman";
import TermsConditions from "./Pages/TermsConditions";
import Apps from "./Pages/Apps";
import EULA from "./Pages/EULA";
import HomeFAQs from "./Pages/HomeFAQs";
import TravelmodeFAQs from "./Pages/TravelmodeFAQs";
import HomeSteps from "./Pages/HomeSteps";
import RegisterMatchmaker from "./Pages/RegisterMatchmaker";
import PageNotFound from "./Pages/404";
import "./Assets/font.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import WithApollo from './graphql/client';

const App = () => {
return (
  <WithApollo>
  <BrowserRouter>
      <Routes>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/matchmakers" element={<Wingman />} />
          <Route path="/travelmode" element={<Wingman />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/eula" element={<EULA />} />
          <Route path="/FAQ" element={<HomeFAQs />} />
          <Route path="/register-matchmaker" element={<RegisterMatchmaker />} />
          <Route path="/travelmodeFAQ" element={<TravelmodeFAQs />} />
          <Route path="/steps" element={<HomeSteps />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" exact element={<Home />}>
          
          {/* <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
    </WithApollo>
)
}

export default App;
