import React, { useEffect } from 'react'
import Header from '../Header';
import './wingman.scss';
import Footer from '../Footer';
import FAQs from '../FAQs';
import WingmanImg from "../../Assets/Wingman/wingman.png";
import Lottie from 'react-lottie';
import * as animationData from '../../lottile-hire-anim'
import { Helmet } from 'react-helmet-async';

const data = [
  {
    title: 'Find a matchmacker. Someone that will help you meet people'
  },
  {
    title: 'Your dedicated Matchmaker gets to know who you really are!'
  },
  {
    title: 'They play the field so you do not have to!'
  },
  {
    title: 'Have fun! You are more likely to meet people with a matchmaker!'
  },
];

const faqs = [
  {
    id: 0,
    title: "Is it Free to become a matchmaker",
    description1: 'Yes, but we only accept people with a track record'
  },
  {
    id: 1,
    title: 'How much do I get paid?',
    description1: 'As a matchmaker, you can set the price you want people to pay you. You can set an hourly rate. You work as you have always worked!'
  },
  {
    id: 2,
    title: "What do I need to submit to become a matchmaker?",
    description1: 'For the matchmaker submission, you must fill a de. Here'
  },
  {
    id: 3,
    title: "How do I apply to become a matchmaker?",
    description1: "In order to become a matchmaker, you must first switch to matchmaker mode in the app. Once you are there, navigate to the profile tab where you will see the application form. ",
  }
];
const Wingman = () => {
  
  const url = `${window.location.origin}${window.location.pathname}`;
  useEffect(() => {
    window.scrollTo(0,0)
  })
  
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  return (
    <>
     <Helmet>
        <title>{"Hire a Matchmaker"}</title>
        <link rel="canonical" href={url} />
      </Helmet>
    <Header />
    <div className='wingman'>
    <div className='main-sect-1'>
    <div className='main-lex-sec-1'>
    <div className='flex-left-sec-1'>
    <h2>Hire a professional Matchmaker</h2>
    <p className='p-1'>Matchamker mode is a feature that is catered to your busy life, it will allow you to meet someone that you actually want to meet! </p>
    <p>They do what you can’t. They ask the tough questions and truly get to know your matches. They help you improve your overall chances </p>
    </div>
    <div className='flex-right-sec-1'>
 <Lottie options={defaultOptions}
              height={400}
              width={400} />
</div>
    </div>
    </div>

    <div className='sec-2-main'>
      <div className='row wingman__card-section'>
        <div className='col-md-6 m-auto order-md-1'>
        <div className='row card-all m-auto'>
        {data.map((item, index) => {
          return (
            <div className='col-md-12 col-sm-12 card-wingman' key={index}>
              <div className='card-wingman__index'>{index + 1}</div>
              <div className='card-wingman__description'>
                {item.title}
              </div>
            </div>
          )
        })}
        </div>
        </div>

        <div className='col-md-6 order-md-2 my-5'>
          <div className='text-center'>
            <img src={WingmanImg} alt="WingmanImg" className='wingman-img'/>
            </div>
        </div>
      </div>
      </div>

      
    <FAQs faqs={faqs}/>
    
    </div>
    <Footer />
    </>
  );
}
export default Wingman;
