import "./404.scss";
import pageNotFoundImg from "../../Assets/Images/404/404.jpeg";

const PageNotFound = () => {
    return (
        <div className="pageNotFound">
            <img src={pageNotFoundImg} alt="pageNotFound" />
            <h2>Quoth the Raven - 404</h2>
            <p>Once upon a midnight dreary, while I pondered weak and weary, Over many a quaint and curious volume of forgotten lore,</p>
            <p>While I nodded, nearly napping, suddenly there came a tapping, As of some one gently rapping, rapping at my chamber door.</p>
            <p>'Tis some visitor,' I muttered, 'tapping at my chamber door - Only this, and nothing more.'</p>
            <h2>Page not found: 404</h2>
        </div>
    )
}

export default PageNotFound;