import Header from '../Header';
import './apps.scss';
import Footer from '../Footer';
import { Helmet } from 'react-helmet-async';

const Wingman = () => {
  const url = `${window.location.origin}${window.location.pathname}`;

  return (
    <>
        <Helmet>
        <title>{"Apps"}</title>
        <link rel="canonical" href={url} />
      </Helmet>
      <Header />
      <div className='apps'>
        <h2>Download Kupenda - The new way to say, "I love you."</h2>

        <div className="buttons">
          <a href="https://apps.apple.com/app/kupenda/id1569230868" target="_blank" rel="noreferrer">
            <img src='https://uploads-ssl.webflow.com/5e6126c70168605e1f969bac/5eae276385c94f79eaacb55f_Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' width={window.innerWidth > 767 ? '245px' : '210px'} alt="app btn" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.kupenda" target="_blank" rel="noreferrer">
            <img src="https://uploads-ssl.webflow.com/5e6126c70168605e1f969bac/5eae280200e6c62f2fec3f59_google-play-badge.svg" width={window.innerWidth > 767 ? '277px' : '210px'} alt="gp btn" />
          </a>
        </div>
      </div>

      <div className='min-width-apps'>

      </div>
      <Footer />
    </>
  );
}
export default Wingman;
