import React, { useState, useReducer } from 'react';
import Header from '../Header';
import CompletedTask from './completedTask';
import './homeSteps.scss';
import Fade from 'react-reveal/Fade';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import InterestStep from './InterestStep';
import HelthHebits from './helthHebits';
import DesireQualities from './DesireQualities';
import useAuthentication from '../../hooks/authentication';
import {differenceInYears, format} from 'date-fns';
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import footerImg from "../../Assets/Header/kupenda-logo.webp";
import { REMOTE_CONFIG_PARAMETERS } from '../../constant/interests';
import {useGetIdealPartnerDescriptionMutation} from '../../generated/graphql'
import { getBoolean, activate, fetchAndActivate } from '@firebase/remote-config';
import { remoteConfig } from '../../Database/firebase';

const chReducer = (state, payload) => ({ ...state, ...payload });
const mindfully = [
  "Self-Development",
  "Spirituallity",
  "Meditation",
  "Yoga",
  "Nutrition",
  "Supporting local",
  "Fitness",
  "Green living",
  "Volunteering"
];

const HomeSteps = () => {

  const {loginWithEmailPassword} = useAuthentication();

  const [state, dispatch] = useReducer(chReducer, {
    pictures: [],
    avatar: '',
    photoID: "",
    gender: '',
    sexualPreference: '',
    birthday: '',
    email: '',
    password: '',
    firstName: '',
    age: 0,
    minAgePreference: 18,
    maxAgePreference: 20,
    interests : [],
    coreValues: [],
    mindfulCommitment: '',
    idealPartner: ''
  });
  
  const [step, setStep] = useState(1);
  const [selectMindfully, setSelectMindfully] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [clickEditIcon, setClickEditIcon] = useState(false);
  const [isEnableChatGPT, setIsEnableChatGPT] = useState(false);
  const [images, setImages] = useState([]);
  const [dob, setDOB] = useState(new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  ));
  const ageCount = differenceInYears(new Date(), dob);

  const navigate = useNavigate();
  const [GetIdealPartnerDescriptionMutation] = useGetIdealPartnerDescriptionMutation();

  const idealDes = "I want a partner who is charismatic, someone who can light up a room with their presence and make everyone feel at ease. They should also have a great sense of humor, someone who can make me laugh and keep the mood light even in tough times. Passion is important to me, so I want a partner who is driven, motivated, and has a clear sense of purpose and direction. Outgoingness is also a must, someone who is social and enjoys meeting new people and trying new things. And last but not least, I need a partner who is strong - reliable, supportive, and always willing to lend a helping hand. Overall, the ideal partner for me is someone who is confident, fun-loving";

  const createUser = async () => {

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email) && state.password) {
      if (state.password === confirmPassword) {
        await loginWithEmailPassword(state.email.trim(), state.password, state);
        setStep(step + 1);
        setErrorMessage(0);
      } else {
        setErrorMessage(2);
      }
    } else {
      setErrorMessage(1);
    }
  };

  const fetchChatGPT = async () => {
   
    try {
      // const showChatGPT = getBoolean(REMOTE_CONFIG_PARAMETERS.ENABLE_CHATGPT);
      await fetchAndActivate(remoteConfig);
      await activate(remoteConfig);
      const showChatGPT = getBoolean(remoteConfig, REMOTE_CONFIG_PARAMETERS.ENABLE_CHATGPT);
      
      if (showChatGPT) {
        const myInterest = state.interests;
        const myCoreValue = state.coreValues;
        const myWellBeing = state.wellBeing ? {
          mindfully: state.wellBeing?.mindfully?.map(item => item),
          mindfulCommitment: state.wellBeing?.mindfulCommitment,
          alcohol: state.wellBeing?.alcohol,
          fitnessGoal: state.wellBeing?.fitnessGoal,
          diet: state.wellBeing?.diet,
          drugs: state.wellBeing?.drugs,
          physActivity: state.wellBeing?.physActivity
        } : undefined

        const response = await GetIdealPartnerDescriptionMutation({
          variables: {
            input: {
              wellBeing: myWellBeing,
              interests: myInterest,
              gender: state.gender,
              maxAgePreference: state.maxAgePreference ?? undefined,
              minAgePreference: state.minAgePreference ?? 18,
              coreValues: myCoreValue
            }
          }
        });
        setIsEnableChatGPT(showChatGPT);
        dispatch({ idealPartner: response.data?.getIdealPartnerDescription
          ?? idealDes });
      } else if (!showChatGPT) {
        setIsEnableChatGPT(false);
        setStep(step + 1);
        dispatch({ idealPartner: idealDes });
      }
    } catch (error) {
      // console.log("error",error);
      setIsEnableChatGPT(false);
      setStep(step + 1);
      dispatch({ idealPartner: idealDes });
    }
  };

  const onContinueInterest = (selectInterests) => {
    dispatch({ interests: selectInterests });
    setStep(step + 1);
  };

  const onChangeSliderValue = (val) => {
      dispatch({ minAgePreference: val[0] });
      dispatch({ maxAgePreference: val[1] });
  };

  const onClickWellBeing = () => {
    setStep(step + 1);
  };

  const helthStepUpdate = (data) => {
    const wellBeingParam = {
      mindfully: selectMindfully,
      mindfulCommitment: state.mindfulCommitment,
      physActivity: data.physActivity,
      drugs: data.drugs,
      fitnessGoal: data.fitnessGoal,
      diet: data.diet,
      alcohol: data.alcohol
    }
    dispatch({ wellBeing: wellBeingParam});
    setStep(step + 1);
  };

  const onClickAgeContinue = () => {
    const newDate = format(dob, 'yyyy-MM-dd');
    const newAge = differenceInYears(new Date(), dob) || 18;

    dispatch({ age: newAge, birthday: newDate});
    setStep(step + 1);
  };

  const updateDesireQualities = (data) => {
    dispatch({ coreValues: data});
    fetchChatGPT();
  };

  const profilePhotoSelect = (e) => {
    const photoProfile = {
      id: e.target.files[0].lastModified,
      type: e.target.files[0].type,
      url: URL.createObjectURL(e.target.files[0])
    };
    dispatch({ avatar: photoProfile })
  };

  const photoSelect = (e) => {
    const photoProfile = {
      id: e.target.files[0].lastModified,
      type: e.target.files[0].type,
      url: URL.createObjectURL(e.target.files[0])
    };
    setImages([...images, photoProfile]);
  };

  const onClickImage = () => {
    dispatch({ pictures: images })
    setStep(step + 1);
  };


  return (
    <div>
      <Header />
      <div className="steps">

        {step === 1 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <CompletedTask step={step} />
              </div>

            </Fade>
            <Fade right>
              <div>
                <h2 className='text-center'>What do you identify as ?</h2>

                <div>
                  <div className={`${state.gender === 'MALE' ? 'active-container': null } checkbox-container d-flex mt-5`} onClick={() =>  dispatch({ gender: 'MALE'})}>
                    <h2>
                      Male
                    </h2>
                    <input
                      type="checkbox"
                      className='checkbox'
                      name='male'
                      value="MALE"
                      // onChange={(e) => handleChangeCheckBox(e) }
                    />
                  </div>
                  <div className={`${state.gender === 'FEMALE' ? 'active-container': null } checkbox-container d-flex mt-3`} onClick={() =>  dispatch({ gender: 'FEMALE'})}>
                    <h2>
                      Female
                    </h2>
                    <input
                      type="checkbox"
                      className='checkbox'
                      name='male'
                      value="FEMALE"
                    />
                  </div>
                  <div className={`${state.gender === 'Neutral' ? 'active-container': null } checkbox-container d-flex mt-3`} onClick={() =>  dispatch({ gender: 'Neutral'})}>
                    <h2>
                      Non-Binary
                    </h2>
                    <input
                      type="checkbox"
                      className='checkbox'
                      name='male'
                      value="Neutral"
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <button className='continue-steps mt-5' disabled={!state.gender} onClick={() => setStep(step + 1)}>
                    Continue
                  </button>
                </div>
              </div>
            </Fade>
          </>
        }

        {step === 2 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <Fade right>
            <div>
                  <div className={`${state.sexualPreference === 'MALE' ? 'active-container': null } checkbox-container d-flex mt-5`} onClick={() =>  dispatch({ sexualPreference: 'MALE'})}>
                    <h2>
                      Male
                    </h2>
                    <input
                      type="checkbox"
                      className='checkbox'
                      name='male'
                      value="MALE"
                      // onChange={(e) => handleChangeCheckBox(e) }
                    />
                  </div>
                  <div className={`${state.sexualPreference === 'FEMALE' ? 'active-container': null } checkbox-container d-flex mt-3`} onClick={() =>  dispatch({ sexualPreference: 'FEMALE'})}>
                    <h2>
                      Female
                    </h2>
                    <input
                      type="checkbox"
                      className='checkbox'
                      name='male'
                      value="FEMALE"
                    />
                  </div>
                  <div className={`${state.sexualPreference === 'Neutral' ? 'active-container': null } checkbox-container d-flex mt-3`} onClick={() =>  dispatch({ sexualPreference: 'Neutral'})}>
                    <h2>
                      Non-Binary
                    </h2>
                    <input
                      type="checkbox"
                      className='checkbox'
                      name='male'
                      value="Neutral"
                    />
                  </div>
                  <div className='text-center'>
                  <button className='continue-steps mt-5' disabled={!state.sexualPreference} onClick={() => setStep(step + 1)}>
                    Continue
                  </button>
                </div>
                </div>
            </Fade>
          </>
        }

        {step === 3 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <Fade right>
              <div>
                <h2 className='text-center'>When is your date of birth?</h2>
                <h6 className='mt-3 text-center'>You must be over 18 to use Kupenda.</h6>
                <div className='mt-3 text-center date-picker-center'>
                  <DatePicker portalId="root-portal" selected={dob} onChange={(date) => setDOB(date)} />
                </div>
              </div>

            </Fade>
          <div className='text-center'>
            <button className='continue-steps mt-5' disabled={ageCount < 18} onClick={() => onClickAgeContinue()}>
              Continue
            </button>
          </div>
          </>
        }

        {step === 4 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <Fade right>
              <div>
                <h2 className='mt-4 text-center'>What is your first name?</h2>
                <div className="mt-4 marginBottom10">
                  <label for="exampleInputPassword1" class="form-label">First name</label>
                  <input type="text" class="form-control" id="exampleInputPassword1" placeholder='First name' value={state.firstName} onChange={(e) =>  dispatch({ firstName: e.target.value })}/>
                </div>
              </div>
              <div className='text-center'>
                <button className='continue-steps mt-5' disabled={!state.firstName} onClick={() => setStep(step + 1)}>
                  Continue
                </button>
              </div>
            </Fade>
          </>
        }

        {step === 5 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <Fade right>
              <div>
                <h2 className='mt-4 text-center'>Photo Time</h2>
                <h6 className='mt-4 text-center'>Let's add a few photos of you that capture truly who you are</h6>

                <div className={`mt-4 marginBottom10 d-flex ${images.length > 1 ? 'photo-time' : null}`}>
                  <label className="custom-file-upload">
                    <input type="file" className='d-none' onChange={(e) => profilePhotoSelect(e)} />
                    <div className='profile-image'>
                      {
                        state.avatar && state.avatar.url ?
                          <img src={state.avatar.url} alt="img" style={{ width: '90%', height: '90%', borderRadius: '50%', marginTop: '5%' }} /> 
                          :
                          <div className='profile-image__text'>
                            <div className='gallery-section__text mb-2'>
                              +
                            </div>
                            Profile Picture
                          </div>
                      }

                    </div>
                  </label>

                  {
                    images.length > 0 ?
                    images.map((pic, index) => {
                        return (
                          <label class="custom-file-upload" key={index}>
                            <input type="file" className='multi-file images-block d-none' id='pictures' accept="image/*" onChange={(e) => dispatch({ pictures: Array.from(e.target.files) })} />
                            <div className='gallery-section'>
                              <img src={pic.url} alt="img" style={{ width: '200px', height: '250px', borderRadius: '5%' }} />
                              <div className='gallery-section__text'>
                                <div>+</div>

                              </div>
                            </div>
                          </label>
                        )
                      })
                      :
                      null
                  }

                  <label class="custom-file-upload">
                    <input type="file" className='multi-file images-block d-none'  onChange={(e) => photoSelect(e)} />
                    <div className='gallery-section'>
                      <div className='gallery-section__text'>
                        <div>+</div>

                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className='text-center'>
                <button className='continue-steps mt-5' disabled={!state.avatar || images.length < 1} onClick={() => onClickImage()}>
                  Continue
                </button>
              </div>
            </Fade>
          </>
        }

        {step === 6 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <Fade right>
              <div>
                <h2 className='mt-4 text-center'>What is the age preference of people you'd like to meet</h2>
                <div className="mt-4 marginBottom10 rangeSlider">

                  <label for="exampleInputPassword1" className="form-label w-100 text-center mb-4">Age range: {state.minAgePreference} - {state.maxAgePreference}</label>
                  <div className='slider-text'>
                    <h3>18</h3>
                    <h3>100</h3>
                  </div>
                  <RangeSlider id="range-slider-yellow"  onInput={(val) => onChangeSliderValue(val)} value={[state.minAgePreference, state.maxAgePreference]} min={18}/>
                </div>
              </div>
              <div className='text-center'>
                <button className='continue-steps mt-5' onClick={() => setStep(step + 1)}>
                  Continue
                </button>
              </div>
            </Fade>
          </>
        }


        {step === 7 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <InterestStep onContinue={onContinueInterest} selected={state.interests}/>
          </>
        }

        {step === 8 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <Fade right>
              <>
              <div>
                <h2 className='mt-4 mb-4 text-center'>About your well being</h2>

                <div className='mt-2 about-well'>
                  <h6 className='text-center'>How Do I choose to live mindfulluy</h6>
                  {mindfully.map((interest, index) => {
                    return (
                      <button key={index} className={`interests-section ${selectMindfully.includes(interest) ? 'interests-section-select' : null}`} onClick={() => setSelectMindfully(nums => nums.includes(interest) ? nums.filter(n => n !== interest) : [...selectMindfully, interest])}>
                        {interest}
                      </button>
                    )
                  })}
                </div>

                <div className='mt-2 about-well'>
                  <h6 className='text-center'>My commitment to the mindful lifestyle is</h6>
                  <div>
                    <input type="radio" value="Casual" checked={'Casual' === state.mindfulCommitment} name="gender" onClick={(e) => dispatch({ mindfulCommitment: e.target.value }) }/> Casual
                  </div>
                  <div>
                    <input type="radio" value="Moderate" checked={'Moderate' === state.mindfulCommitment} name="gender" onClick={(e) => dispatch({ mindfulCommitment: e.target.value }) }/> Moderate
                  </div>
                  <div>
                    <input type="radio" value="Serious" checked={'Serious' === state.mindfulCommitment} name="gender" onClick={(e) => dispatch({ mindfulCommitment: e.target.value }) }/> Serious
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <button className='continue-steps mt-5' disabled={!state.mindfulCommitment || selectMindfully.length < 1} onClick={() => onClickWellBeing()}>
                  Continue
                </button>
              </div>
              </>
            </Fade>
          </>
        }

        {step === 9 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <HelthHebits stepUpdate={helthStepUpdate} selected={state.wellBeing}/>
          </>
        }

        {step === 10 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <DesireQualities updateSteps={updateDesireQualities} selected={state.coreValues}/>
          </>
        }

        {step === 11 &&
          <>
          {
            state.idealPartner ? 
            <>
                  <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <Fade right>
              <>
              <div>
                <h2 className='mt-4 text-center'>Perfect!</h2>
                <h6 className='text-center mb-4'>We Successfully created a description of your ideal partner</h6>
                <h5 className='mt-1 text-center mb-5'>Here is what your ideal partner sounds like</h5>
                      {
                        clickEditIcon ?
                          <textarea rows="10" className='w-100' value={state.idealPartner} onChange={(e) => dispatch({ idealPartner: e.target.value})}>{state.idealPartner}</textarea>
                          :
                          <p>{state.idealPartner}</p>
                      }
                    </div>
                    <div className='text-center d-flex justify-content-center'>
                      {
                        clickEditIcon ?
                          <button className='continue-steps mt-5' onClick={() => setClickEditIcon(false)}>
                            Save
                          </button> :
                          <>
                            <div className='arrow-edit mt-5' onClick={() => setClickEditIcon(true)}>
                              <i className="fa fa-edit" />
                            </div>
                            <button className='continue-steps mt-5' onClick={() => setStep(step + 1)}>
                              Continue
                            </button>
                          </>
                      }
             
              </div>
              {isEnableChatGPT && <div className='text-center'>
                <button className='continue-steps mt-5' onClick={() => setStep(step - 1)}>
                  Generate Again
                </button>
              </div>}
              </>
            </Fade>
            </> : 
                    <div className="spinner-container">
                    <div className="loading-spinner m-auto">
                    </div>
                    </div>
          }
          </>
        }

        {step === 12 &&
          <>
            <Fade right>
              <div className='d-flex mb-5'>
                <div className='arrowBG' onClick={() => setStep(step - 1)}>
                  <i className="fa fa-arrow-left" />
                </div>
                <CompletedTask step={step} />
              </div>
            </Fade>
            <Fade right>
              <h2 className='text-center'>Sign Up</h2>
              <div className="marginBottom10">
                <label for="exampleInputEmail1" class="form-label">Email address</label>
                <input type="email" value={state.email} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Email address' onChange={(e) =>  dispatch({ email: e.target.value })}/>
                {errorMessage === 1 && <div className='validation-msg'>Invalid email address.</div> }
              </div>
              <div className="marginBottom10">
                <label for="exampleInputPassword1" class="form-label">Password</label>
                <input type="password" value={state.password} class="form-control" id="exampleInputPassword1" placeholder='Password'  onChange={(e) =>  dispatch({ password: e.target.value })}/>
              </div>
              <div className="marginBottom10">
                <label for="exampleInputPassword2" class="form-label">Confirm Password</label>
                <input type="password" value={confirmPassword} class="form-control" id="exampleInputPassword2" placeholder='Confirm password' onChange={(e) =>  setConfirmPassword(e.target.value)} />
                {errorMessage === 2 && <div className='validation-msg'>Password does not match</div> }
              </div>
              <div className='text-center'>
                <button className='continue-steps mt-5' disabled={!state.email || !state.password} onClick={() => createUser()}>
                  Done
                </button>
              </div>
            </Fade>
          </>
        }

        {step === 13 &&
          <>
            <Fade right>
            <img src={footerImg} alt={"logo"}/>
            <h5 className='mt-4 mb-4'>Download Kupenda to start your journey. Login with the credentials you just created once inside the app.</h5>
            <div className='text-center'>
              <QRCode value="https://apps.apple.com/app/kupenda/id1569230868" />
            </div>
              <div className='text-center'>
                <button className='continue-steps mt-5' onClick={() => navigate('/')}>
                  Done
                </button>
              </div>
            </Fade>
          </>
        }
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default HomeSteps;
