import FAQs from '../FAQs';
import { Helmet } from 'react-helmet-async';

const TravelFAQs = () => {
  const url = `${window.location.origin}${window.location.pathname}`;
  const faqs = [
    {
      id: 0,
      title: "Is it Free to become a wingman?",
      description1: 'To become a wingman, you must have earned at least 1000 points or be subscribed to premium account.'
    },
    {
      id: 1,
      title: 'How much do I get paid?',
      description1: 'As a wingman, you can set the price you want people to pay you to take you around the city and meet up. You can set an hourly rate.'
    },
    {
      id: 2,
      title: "What do I need to submit to become a wingman?",
      description1: 'For the wingman submission, you must submit a description of why you would like to apply. Next you must submit your phone number and a photo of your ID so we can verify you.'
    },
    {
      id: 3,
      title: "How do I apply to become a wingman?",
      description1: "For To become a wingman, you must first switch to wingman mode in the app. Once you are there, navigate to the profile tab where you will see the application form. ",
    }
  ];
  return (
    <>
      <Helmet>
        <title>{"FAQs"}</title>
        <link rel="canonical" href={url} />
      </Helmet>
      <FAQs faqs={faqs} />
    </>
  );
}
export default TravelFAQs;
