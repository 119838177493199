import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import {
    useUpdateUserMutation,
  } from '../generated/graphql';

const useAuthentication = () => {
  const [updateUserMutate] = useUpdateUserMutation();

  const loginWithEmailPassword = async (email, password, fullParam) => {
    
    const authentication = getAuth();
    try {
        createUserWithEmailAndPassword(authentication, email, password)
        .then(async (response) => {
          console.log("res",response.user.uid)
          const res = await updateUserMutate({
            variables: {
              updateUserId: response.user.uid,
              input: fullParam,
            },
            errorPolicy: 'all'
          });

          console.log("user data addded successsfully,,",res);
      })
    //   await auth().signInWithEmailAndPassword(email, password);
    //   await _handleAfterUserLoggedIn();
    //   setUserProperties({
    //     login_method: USER_REGISTERED_METHOD.EMAIL
    //   })
    } catch (err) {
        console.log("errer are come",err)
    //   if (err.code === 'auth/user-not-found') {
    //     setIsLoading(false);
    //     showToast('User not found');
    //   } else {
    //     setIsLoading(false);
    //     showToast('Something went wrong!');
    //   }
    }
  };

  return {
    loginWithEmailPassword,
  };
};

export default useAuthentication;
