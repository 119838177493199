import React from 'react';
import './home.scss';
import Lottie from 'react-lottie';
import Slider from 'react-slick';
import dailyApage from '../../Assets/HomeGIF/dailyApage.json';
import chatImage from '../../Assets/Images/chat.webp';
import findYourMatchImage from '../../Assets/Images/Find-your-match.webp';
import giftImage from '../../Assets/Images/Gift-card.webp';
import Header from '../Header';
import Footer from '../Footer';
import FAQs from '../FAQs';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";

const faqs = [
  {
    id: 0,
    title: "What is Kupenda?",
    description1: 'Kupenda aims to help you and others make meaningful connections while helping others.'
  },
  {
    id: 1,
    title: 'How does Kupenda make connections?',
    description1: "Each time you open the app you’ll have the opportunity to meet someone for friendship, love or business in more ways that you can hope for. Kupenda helps everyone find whatever they’re looking for by involving everybody in everyone's success. More your help others find a match, the more the app is valuable for yourself. You effectively become a matchmaker."
  },
  {
    id: 2,
    title: "Is Kupenda free?",
    description1: "Kupenda is free to download and use. You can view 3 profiles for free every day. There are also premium features  you can access with a subscription and match with more users."
  },
  {
    id: 3,
    title: 'How does Kupenda keep my info safe?',
    description1: "Kupenda values your privacy. Our app and web services use bank-level 128-bit encryption to securely transmit data between your device and our servers. Customer data stored in our databases is logically isolated and encrypted. To further safeguard your personal data, we employ extensive security measures."
  },
  {
    id: 4,
    title: "Can I use Kupenda if I'm single?",
    description1: "Yes, you can match other couples in Matchmaker Mode©, and use Matchmaker Mode© to find a city companion that can show you around.",
  },
  {
    id: 5,
    title: "How do points and monthly giveaways work?",
    description1: 'Our Point-Based Rewards System is designed to encourage you to help others find a match. So the more you engage with Kupenda, the more points you earn.',
    description2: '100 points can be redeemed as 1 entry into our monthly giveaways. The winner is randomly selected from the available entries.'
  }
];

const Home = () => {
  const url = `${window.location.origin}${window.location.pathname}`;

  const defaultOptions4 = {
    loop: true,
    autoplay: true,
    animationData: dailyApage
  };

  const sliderSettings = {
    dots: true,
    speed: 500,
    arrows: true,
    infinite: true,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
    ],
  };

  const testimonials = [
    {
      description: 'I can earn a gift card every month',
      name: 'YOU!?',
    },
    {
      description: 'Kupenda helped me bond through a fun chat game where I had to guess what others thought about us and eventually helped me find my fiance!',
      name: 'YOU!?',
    },
    {
      description: 'I would have never imagined something so simple could be so impactful. Matchmaker mode is life changing— I was able to meet new people in the city.',
      name: 'YOU!?',
    },
    {
      description: 'I met my best friend at an event I found on Kupenda. We immediately hit it off!',
      name: 'YOU!?',
    },

  ];

  const tesinew = [
    {
      image: giftImage,
      name: 'Earn Gift cards',
      description: 'Be rewarded for playing Cupid.',
    },
    {
      image: chatImage,
      name: 'Chat',
      description: 'Let us drive the conversations',
    },
    {
      image: findYourMatchImage,
      name: 'Find',
      description: 'Find your match',
    },
  ];

  const dataKupenda = [
    {
      image: '5e680e3570623f02f53e91d6_icon-1.svg',
      title: 'Fun + Easy',
      description: 'Games that encourage you to exercise your human intuition & help others. Earn monthly rewards!'
    },
    {
      image: '5e680e35ba4c6f0e60609552_icon-2.svg',
      title: 'Intuitive & Unique',
      description: 'Matching other people requires you to think creatively and apply emotional intelligence.'
    },
    {
      image: '5e680e355aa1945d8e74e608_icon-3.svg',
      title: 'Personalized',
      description: 'We ditched the mundane algorithms that match you based on basic similarities. Instead, we use the power of the crowd (other people’s suggestions) to find and suggest the best matches for you.'
    },
    {
      image: '5e680e35c964bd0515ef51ef_icon-4.svg',
      title: 'Effective',
      description: 'Stop wasting time swiping.With Kupenda, you can wake up to matches made for you by others.'
    },
  ];

  return (
    <>
       <Helmet>
        <title>{""}</title>
        <link rel="canonical" href={url} />
      </Helmet>
      <Header />
      <div className="onboarding">
        <div className='onboarding_flex'>
          <div className="onboarding__heading-section">
            {/* <h1>What type of connection would you like to make?</h1> */}
            <h1 className="mb-0">We are Kinder. </h1>
            <h5 className="onboarding__not-everyone">not for everyone</h5>
            <div className="heading-description"> A community focused on mindful dating.  Have meaningful conversations and meet your next romantic partner, best friend, business partner, or whatever you’re looking for. </div>

            <div className="onboarding__btn-start">
              <Link to="/steps">
              <button className='button-start-here'>
                START HERE
              </button>
              </Link>
            </div>

            <div className="heading-buttons">
              <a href="https://apps.apple.com/app/kupenda/id1569230868" target="_blank" rel="noreferrer">
                <img src='https://uploads-ssl.webflow.com/5e6126c70168605e1f969bac/5eae276385c94f79eaacb55f_Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' className="ios" width={window.innerWidth > 767 ? '245px' : '210px'} alt="app btn" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.kupenda" target="_blank" rel="noreferrer">
                <img src="https://uploads-ssl.webflow.com/5e6126c70168605e1f969bac/5eae280200e6c62f2fec3f59_google-play-badge.svg" className='app' width={window.innerWidth > 767 ? '277px' : '210px'} alt="gp btn" />
              </a>
            </div>
          </div>
          <div className='onboarding-img'>
            <img src='headerimg.webp' alt='header-img'></img>
          </div>
        </div>

        <div className='onboarding__quote'>
          <h2 className="mb-0">"The Outer World Follows The Inner World. Not The Other Way Around." On Kupenda, your well being comes first And foremost.</h2>
        </div>

        <div className="onboarding__how-work row">
          <div className='col-md-6 m-auto onboarding__how-work-text'>
            <h2>How does Kupenda work?</h2>
            <div className='description'>
              <ul>
                <li className='description-li'>Kupenda helps you find friends, lovers, business partners, or whatever you’re looking for by involving the entire community in everyone's success.</li>
                <li className='description-li'>You’re rewarded every-time you help someone else make a connection. We’ll automatically enter you in a raffle each month to win a gift card</li>
                <li className='description-li'>We are focused on self care</li>
              </ul>
            </div>
          </div>


          <div className='col-md-6 onboarding__how-work-image'>

            <a href="#_" class="lightbox" id="img1">
              <div id="videoModal" class="modal hide fade in" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="false" >
                <div class="modal-header">
                  <button type="button" class="close full-height" data-dismiss="modal" aria-hidden="true">X</button>
                </div>
                <div class="modal-body">

                  <video width="100%" height="100%" playsInline autoPlay muted loop controls>
                    <source src="https://d1x4b7qmb7lvb8.cloudfront.net/onboarding/KUPENDA-1080p-230203.mp4" type="video/mp4" />
                  </video>

                </div>
              </div>
            </a>

            <div className="onboarding__testimonials new-slider-main">

              <div class="Icon-howItWorks">
                <h2>
                  <a href="#img1">
                     <span className='span-img'>
                  <img src='free-video-icon-818-thumb.webp' alt="img" />
                  </span>
                    <span>How it works</span>
                  </a></h2>
              </div>

              <Slider className="el-slider el-slider-plr--15 mb-100 new-slider-div" {...sliderSettings}>
                {tesinew.map((item, index) => (
                  <div key={index}>
                    <div className='img-testi-div'>
                      <img src={item.image} alt="img" />
                    </div>
                    <h6><hr />{item.name}</h6>
                    <div className='description'>
                      {item.description}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>


          </div>



        </div>


        <div className="onboarding__testimonials sec-testimonial">

          <div>
            <h2>What we hope you’ll say about us:</h2>
          </div>

          <Slider className="el-slider el-slider-plr--15 mb-100" {...sliderSettings}>
            {testimonials.map((item, index) => (
              <div key={index}>
                <div className='description'>
                  {item.description}
                </div>
                <div>
                  <h6><hr />{item.name}</h6>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                </div>
                <br></br>
              </div>
            ))}
          </Slider>
        </div>


        <div className='onboarding__daily-kupenda'>
          <h2>Perfect for people who want to better themselves. We believe in mindful dating</h2>

          <Lottie options={defaultOptions4} width={window.innerWidth > 1200 ? 700 : window.innerWidth > 450 ? 400 : 300} />
        </div>

        <div className="onboarding__why-kupenda">
          <h2>Why Kupenda</h2>

          <div className='row'>
            {dataKupenda.map((item, index) => {
              return (
                <div className='col-md-6 col-12 why-kupenda-cards' key={index}>
                  <img src={item.image} alt={"img"} />
                  <div>
                    <div className='card-title'>
                      {item.title}
                    </div>
                    <div className='card-description'>
                      {item.description}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <FAQs faqs={faqs} />
        <Footer />
      </div>
    </>
  );
}

export default Home;
