import React, { useState } from 'react';
import './homeSteps.scss';
import { insterestData } from '../../constant/interests';
import Fade from 'react-reveal/Fade';
import Modal from 'react-bootstrap/Modal';


const MyVerticallyCenteredModal = (props) => {
  const [singleInetrest, setSingleInterest] = useState();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          New Tag
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type="text" class="form-control" id="exampleInputPassword1" placeholder='Type a tag name' value={singleInetrest} onChange={(e) => setSingleInterest(e.target.value)} />
      </Modal.Body>
      <Modal.Footer>
        <div className='text-center steps'>
          <button className='continue-steps' onClick={() => props.addNewInterest(singleInetrest)}>
            Add
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const InterestStep = (props) => {

  const {onContinue, selected} = props;

  const [selectInterests, setSelectInterests] = useState(selected.length > 0 ? selected : []);
  const [interests, setInterests] = useState(insterestData);

  const [modalShow, setModalShow] = useState(false);

  const addNewInterest = (data) => {
    !interests.includes(data) && setInterests([...interests, data]);
    setModalShow(false);
  };

  const addRemoveArray = (item) => {
    selectInterests.length > 4 ? selectInterests.includes(item) ? setSelectInterests(nums => nums.filter(n => n !== item)) :alert("You have added the maximin number of interests.") :
      setSelectInterests(nums => nums.includes(item) ? nums.filter(n => n !== item) : [...selectInterests, item]);
  };

  return (
    <>
      <Fade right>
        <div>
          <h2 className='mt-4 text-center'>What are your interests</h2>
          <h6 className='mt-4 text-center mb-5'>Select passions that you'd like to share Choose a minimum of 3</h6>
          <div className='fix-div-size'>
            {interests.map((interest, index) => {
              return (
                <button key={index} className={`interests-section ${selectInterests.includes(interest) ? 'interests-section-select' : null}`} onClick={() => addRemoveArray(interest)}>
                  {interest}
                </button>
              )
            })}
          </div>
        </div>

        <div className="mt-5">
          <button className={`interests-section px-5`} onClick={() => setSelectInterests([])}>
            Reset
          </button>
          <button className={`interests-section px-5 add-new-button`} onClick={() => setModalShow(true)}>
            Add New
          </button>
        </div>
        <div className='text-center'>
          <button className='continue-steps' disabled={selectInterests.length < 5} onClick={() => onContinue(selectInterests)}>
            Continue
          </button>
        </div>
      </Fade>

      {modalShow && <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        addNewInterest={addNewInterest}
      />}
    </>
  );
}

export default InterestStep;
