import {gql} from '@apollo/client';
import * as Apollo from '@apollo/client';

/** All built-in and custom scalars, mapped to their actual values */
const defaultOptions = {};

export const UpdateUserDocument = gql`
  mutation updateUser($updateUserId: ID!, $input: UpdateUserInput!) {
    updateUser(id: $updateUserId, input: $input) {
      id
      avatar {
        id
        type
        url
      }
      firstName
      lastName
      userName
      email
      emailConfirmed
      phone
      age
      gender
      birthday
      ethnicity
      isSmoker
      hasChildren
      languages
      location {
        city
        country
        latitude
        longitude
      }
      height {
        unit
        value
      }
      goals
      isPaidMembership
      interests
      regularCheckins {
        totalPercentage
        finance
        career
        optimistic
        grateful
        health
        goalDriven
        spiritualPractice
        knowYourStrengths
      }
      audioBytes {
        id
        duration
        url
        type
        title
        created
      }
      pictures {
        id
        type
        url
      }
      coreValues
      idealPartner
      earnedPoints
      sexualPreference
      ethnicityPreference
      locationPreference {
        city
        country
        latitude
        longitude
      }
      minAgePreference
      maxAgePreference
      maxDistancePreference
      showAge
      profileCompleted
      loginProvider
      isHomeScreenTutorialCompleted
      isMatchmakingScreenTutorialCompleted
      isMatchmakerModeScreenTutorialCompleted
      isMatchmakeDisabled
      wellBeing {
        alcohol
        diet
        drugs
        fitnessGoal
        mindfulCommitment
        mindfully
        physActivity
      }
      wingmanProfile {
        visible
        languages
        uniqueQuote
        totalRatings
        pricePerHour
        pictures {
          id
          type
          url
        }
        location {
          longitude
          latitude
          country
          city
        }
        description
        currency
        avatar {
          id
          type
          url
        }
        activities
      }
    }
  }
`;

export const GetIdealPartnerDescriptionDocument = gql`
  mutation GetIdealPartnerDescription($input: Chatgpt!) {
    getIdealPartnerDescription(input: $input)
  }
`;

export function useUpdateUserMutation(
  baseOptions,
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation(
    UpdateUserDocument,
    options,
  );
};

export function useGetIdealPartnerDescriptionMutation(
  baseOptions,
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation(GetIdealPartnerDescriptionDocument, options);
}