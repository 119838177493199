import React, {useState} from 'react';
import './Header.scss';
import { Link, NavLink } from "react-router-dom";
import headerLogo from '../../Assets/Header/kupenda-logo.webp'

const Header = () => {
    const [closeIcon, setCloseIcon] = useState(false);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-header">
            <Link className="navbar-brand" to="/">
                <img src={headerLogo} alt="header-logo"/>
            </Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation"
             onClick={()=>setCloseIcon(!closeIcon)}>
                {closeIcon ? 
                <span className="my-1 mx-2 close">X</span> :
                <span className="navbar-toggler-icon"></span>
            }
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                    <NavLink className="nav-link" to="/">Home</NavLink>
                    </li>  
                    {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/guide">Hire a Matchmaker</NavLink>
                    </li> */}
                  
                    <li className="nav-item">
                    <NavLink className="nav-link" to="/privacy">Privacy</NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink className="nav-link" to="/terms-conditions">Terms & Conditions</NavLink>
                    </li>

                    <NavLink className="nav-link" to="/apps">
                        <button className='button-header'>
                            Let's Begin
                        </button>
                    </NavLink>
                </ul>
            </div>
        </nav>
    )
}

export default Header;
