import React, {useState} from 'react';
import './homeSteps.scss';
import Fade from 'react-reveal/Fade';
import { PhysData, drugData, alcoholData, dietData, fitnessData } from '../../constant/interests';

const HelthHebits = (props) => {

  const {stepUpdate, selected} = props;

  const [alcohol, setAlcohol] = useState(selected && selected.alcohol ? selected.alcohol : null);
  const [diet, setDiet] = useState(selected && selected.diet ? selected.diet : null);
  const [drugs, setDrugs] = useState(selected && selected.drugs ? selected.drugs : null);
  const [fitnessGoal, setFitnessGoal] = useState(selected && selected.fitnessGoal ? selected.fitnessGoal : null);
  const [physActivity, setPhysActivity] = useState(selected && selected.physActivity ? selected.physActivity : null);


  const onClickContinue = () => {
    const mindful = {
      alcohol,
      diet,
      drugs,
      fitnessGoal,
      physActivity
    };

    stepUpdate(mindful);
  };

  return (
    <Fade right>
    <div>
      <h2 className='mt-4 mb-4 text-center'>What are your health habits</h2>

      <div className='row'>
        <div className='col-md-6 mt-2 mb-5'>
          <div className='about-well'>
            <h6>Phys Activity</h6>
            <select className='selctbox-habits' value={physActivity} name='physActivity' onChange={(e) => setPhysActivity(e.target.value)}>
            <option></option>
              {PhysData.map((selectOption, index) => {
                return (
                  <option value={selectOption} key={index}>{selectOption}</option>
                )
              })}
            </select>
          </div>
        </div>
        <div className='col-md-6 mt-2 mb-5'>
          <div className='about-well'>
            <h6>Drugs</h6>
            <select className='selctbox-habits' value={drugs} name='drugs' onChange={(e) => setDrugs(e.target.value)}>
            <option></option>
              {drugData.map((selectOption, index) => {
                return (
                  <option value={selectOption} key={index}>{selectOption}</option>
                )
              })}
            </select>
          </div>
        </div>
        <div className='col-md-6 mt-2 mb-5'>
          <div className='about-well'>
            <h6>Alcohol</h6>
            <select className='selctbox-habits' value={alcohol} name='alcohol' onChange={(e) => setAlcohol(e.target.value)}>
            <option></option>
              {alcoholData.map((selectOption, index) => {
                return (
                  <option value={selectOption} key={index}>{selectOption}</option>
                )
              })}
            </select>
          </div>
        </div>
        <div className='col-md-6 mt-2 mb-5'>
          <div className='about-well'>
            <h6>Diet</h6>
            <select className='selctbox-habits' value={diet} name='diet' onChange={(e) => setDiet(e.target.value)}>
            <option></option>
              {dietData.map((selectOption, index) => {
                return (
                  <option value={selectOption} key={index}>{selectOption}</option>
                )
              })}
            </select>
          </div>
        </div>

        <div className='col-md-6 mt-2 mb-5'>
          <div className='about-well'>
            <h6>Fitness Goal</h6>
            <select className='selctbox-habits' value={fitnessGoal} name='fitnessGoal' onChange={(e) => setFitnessGoal(e.target.value)} >
            <option></option>
              {fitnessData.map((selectOption, index) => {
                return (
                  <option value={selectOption} key={index}>{selectOption}</option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
    <div className='text-center'>
        <button className='continue-steps mt-5' disabled={!alcohol ||
          !diet ||
          !drugs ||
          !fitnessGoal ||
          !physActivity} onClick={() => onClickContinue()}>
          Continue
        </button>
    </div>
  </Fade>
  );
}

export default HelthHebits;
