import React, { useState } from 'react';
import './homeSteps.scss';
import Fade from 'react-reveal/Fade';
import { CORE_VALUES_DATA } from '../../constant/interests';

const DesireQualities = (props) => {

  const {updateSteps, selected} = props;
  const [selectInterests, setSelectInterests] = useState(selected.length > 0 ? selected : []);


  const addRemoveArray = (item) => {
    selectInterests.length > 4 ? selectInterests.includes(item) ? setSelectInterests(nums => nums.filter(n => n !== item)) :alert("You have added the maximin number of interests.") :
      setSelectInterests(nums => nums.includes(item) ? nums.filter(n => n !== item) : [...selectInterests, item]);
  };

  return (
    <>
    <Fade right>
              <div>
                <h2 className='mt-4 text-center'>Desired qualities</h2>
                <h6 className='text-center mb-4'>Choose 5 qualities you look for in a potential partner</h6>
                <h6 className='mt-1 text-center mb-5'>Select qualities you are looking for in someone. Choose a maximum of 5. We will do the rest</h6>
                <div className='fix-div-size'>
                  {CORE_VALUES_DATA.map((interest, index) => {
                    return (
                      <button key={index} className={`interests-section ${selectInterests.includes(interest) ? 'interests-section-select' : null}`} onClick={() => addRemoveArray(interest)}>
                        {interest}
                      </button>
                    )
                  })}
                </div>
              </div>
              <div className='text-center'>
                <button className='continue-steps mt-5' disabled={selectInterests.length < 5} onClick={() => updateSteps(selectInterests)}>
                  Generate
                </button>
              </div>
            </Fade>
    </>
  );
}

export default DesireQualities;
