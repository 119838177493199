import React from 'react';
import {ApolloClient, createHttpLink, InMemoryCache, ApolloProvider} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';
import {setContext} from '@apollo/client/link/context';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export const TOKEN_KEY = 'token';
const graphqlEndpoint = process.env.GRAPHQL_ENDPOINT;
const httpLink = createHttpLink({
  uri: graphqlEndpoint,
});

/**
 * Get JWT token from storage and also check expiration.
 */
export const getToken = async () => {
  const user = firebase.auth().currentUser;
  if (user) {
    return await user.getIdToken();
  }
  return '';
};
const authLink = setContext(async (_, {headers}) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getRecommendedUsers: relayStylePagination(),
          getRecommendedWingmen: relayStylePagination(),
          getMyMatches: {
            merge: (existing, incoming) => {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

const WithApollo = ({children}) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default WithApollo;
