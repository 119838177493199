import FAQs from '../FAQs';
import { Helmet } from 'react-helmet-async';

const HomeFAQs = () => {
  const url = `${window.location.origin}${window.location.pathname}`;

  const faqs = [
    {
      id: 0,
      title: "What is Kupenda?",
      description1: 'Kupenda aims to help you make meaningful connections, and in the process help others find connections. How? By recommending other profiles with eachother if you think they have chemistry.'
    },
    {
      id: 1,
      title: 'How does Kupenda make connections?',
      description1: 'You will receive new potential matches daily based on your ideal match. Using wingman mode, you will have access to events in your city to meet people. We will connect you to wingmen or wingwomen. Yes! people willing to go the extra mile for you and take you to the hot spots to connect with people.',
      description2: `Everybody in the app has an incentive to help you find a match. To improve anything we first need to know what you want. Invest in your profile and we will do the rest!`
    },
    {
      id: 2,
      title: "Is Kupenda free?",
      description1: 'Kupenda is free to download and free to use. You can see daily 3 profile for free. There are also premium features that you can access with a subscription and match with more users.',
      description2: "We have a free and premium version of the app. It is important to us that you can benefit from kupenda even if you can't afford to support us financially."
    },
    {
      id: 3,
      title: 'How does Kupenda keep my info safe?',
      description1: 'Kupenda values your privacy. Our app and web services use bank-level 128 bit encryption to securely transmit data between your device and our servers. Customer data stored in our databases is logically isolated and encrypted. To further keep your personal data safe, we employ extensive security measures to minimize access.'
    },
    {
      id: 4,
      title: "Can I use Kupenda if I'm single?",
      description1: "Yes, you can match other couples in Matchmaker Mode©, and use Wingman Mode© to find a city companion that can show you around.",
    },
    {
      id: 5,
      title: "How do points and monthly giveaways work?",
      description1: 'Our Point Based Rewards System is designed to encourage you to help others find a match. So the more you engage with Kupenda the more points you earn.',
      description2: '100 points can be redeemed as 1 entry into our monthly giveaways. The winner is randomly selected from the available entries.'
    }
  ];
  return (
    <>
      <Helmet>
        <title>{"FAQs"}</title>
        <link rel="canonical" href={url} />
      </Helmet>

      <FAQs faqs={faqs} />
    </>
  );
}
export default HomeFAQs;
