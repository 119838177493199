import React from 'react';
import './homeSteps.scss';

const CompletedTask = (props) => {

  const {step} = props;

  return (
    <div className='taskView m-auto'>
      <div>
        <hr style={{background: 'black', opacity:0.1}}/>
        <hr style={{background: '#fc5033', width: (step * 100) / 12 + '%', opacity:1, position: 'relative', bottom: 5}}/>
      </div>
    </div>
  );
}

export default CompletedTask;
