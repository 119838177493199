export const insterestData = [
  '90s Kid',
  'Self Care',
  'Hot Yoga',
  'Writing',
  'Meditation',
  'Sushi',
  'Hockey',
  'Basketball',
  'Slam Poetry',
  'Home Workout',
  'Manga',
  'Makeup',
  'Aquarium',
  'Sneakers',
  'Instagram',
  'Hot Springs',
  'Martial Arts',
  'Marvel',
  'Walking',
  'Running',
  'Travel',
  'Language Exchange',
  'Movies',
  'Gym',
  'Social Media',
  'Skincare',
  'Cricket',
  'Skateboarding',
  'Vegan Cooking',
  'K-Pop',
  'Photography',
  'Reading',
  'Singing',
  'Volleyball',
  'Sports',
  'Vinyasa',
  'Poetry',
  'Stand up Comedy',
  'Coffee',
  'League of Legends',
  'Karaoke',
  'Fortnite',
  'Free Diving',
  'Foodie Tour',
  'Jiu-jitsu',
  'Climate Change',
  'NFTs',
  'Exhibition',
  'Bar Hopping',
  'Baseball',
  'LGBTQ+ Rights',
  'Ballet',
  'Feminism',
  'Band',
  'Escape Cafe',
  'Sailing',
  'Shopping',
  'Brunch',
  'Reggaeton',
  'Second-hand apparel',
  'Road Trips',
  'Memes',
  'Country Music',
  'Motorcycling',
  'Football',
  'Acapella',
  'Investing',
  'Art Galleries',
  'Hiking',
  'Mountains',
  'Archery',
  'Backpacking',
  'Fishing',
  'Ice Cream',
  'Ice Skating',
  'Expositions',
  'Skiing',
  'Snowboarding',
  'Pilates',
  'Clubbing',
  'Broadway',
  'Cheerleading',
  'Choir',
  'Car Racing',
  'Street Food',
  'Festivals',
  'Catan',
  'Crossfit',
  'Cosplay',
  'Motor Sports',
  'Content Creation',
  'E-Sports',
  'Concerts',
  'Climbing',
  'Baking',
  'Camping',
  'Blogging',
  'Collecting',
  'Cars',
  'Bicycle Racing',
  'Start ups',
  'Boba Tea',
  'Binge-Watching TV shows',
  'Songwriter',
  'Tattoos',
  'Painting',
  'Paddle Boarding',
  'Padel',
  'Surfing',
  'Badminton',
  'Bowling',
  'Active Lifestyle',
  'Beach Bars',
  'Fashion',
  'Anime',
  'Astrology',
  'Motorcycles',
  'Entrepreneurship',
  'Cooking',
  'Fencing',
  'Soccer',
  'Dancing',
  'Gardening',
  'Amateur Cook',
  'Art',
  'DIY',
  'Politics',
  'Cycling',
  'Museum',
  'Outdoors',
  'TikTok',
  'Activism',
  'Picnicking',
  'Twitch',
  'Comedy',
  'Music',
  'Triathlon',
  'Netflix',
  'Disney',
  'Rugby',
  'DAOs',
  'Real Estate',
  'Podcasts',
  'BBQ',
  'Craft Beer',
  'Stocks',
  'Swimming',
  'Iced Tea',
  'Drummer',
  'Tea',
  'Board Games',
  'Drawing',
  'Trivia',
  'Volunteering',
  'Table Tennis',
  'Environmentalism',
  'Rollerskating',
  'Wine',
  'Dungeons & Dragons',
  'Vlogging',
  'Electronic Music',
  'Working out',
  'Yoga',
  'Boxing',
  'Ramen',
];

export const CORE_VALUES_DATA = [
  'Authenticity',
  'Achievement',
  'Adventure',
  'Authority',
  'Autonomy',
  'Balance',
  'Beauty',
  'Boldness',
  'Compassion',
  'Challenge',
  'Citizenship',
  'Community',
  'Competency',
  'Contribution',
  'Creativity',
  'Curiosity',
  'Determination',
  'Fairness',
  'Faith',
  'Fame',
  'Friendships',
  'Fun',
  'Growth',
  'Happiness',
  'Honesty',
  'Humor',
  'Influence',
  'Inner Harmony',
  'Justice',
  'Kindness',
  'Knowledge',
  'Leadership',
  'Learning',
  'Love',
  'Loyalty',
  'Meaningful Work',
  'Openness',
  'Optimism',
  'Peace',
  'Pleasure',
  'Poise',
  'Popularity',
  'Recognition',
  'Religion',
  'Reputation',
  'Respect',
  'Responsibility',
  'Security',
  'Self-Respect',
  'Service',
  'Spirituality',
  'Stability',
  'Success',
  'Status',
  'Trustworthiness',
  'Wealth',
  'Wisdom',
];


export const PhysData = [
  "Rarely",
  "Often",
  "Weekly",
  "Daily",
];

export const drugData = [
  "Never",
  "I outgrew it",
  "420 only",
  "Experimental",
  "Hardcore",
  "Rather not say"
];

export const alcoholData = [
  "Never",
  "Once in a while",
  "Usually",
  "Daily",
  "Rather not say"
];

export const dietData = [
  "I Eat anything",
  "Health-conscious",
  "Gluten free",
  "Pescetarian",
  "Atkins diet",
  "Vegetarian",
  "Vegan",
  "Paleo diet",
  "Pegan diet",
  "The Zone diet",
  "Ketogenic diet",
  "Vegetarian diet",
  "Vegan diet",
  "Weight Watchers diet",
  "Raw food diet",
  "Mediterranean diet",
  "The Ayurvedic diet"
];

export const fitnessData = [
  "Strength-training: Get stronger and lift more weight",
  "Bodybuilding: Increase muscle mass and size",
  "Muscle tone: Tone muscle and lose weight",
  "General fitness",
  "Improve stamina",
  "Power weight lifting",
  "Olympic weight lifting",
  "Other",
];

export const REMOTE_CONFIG_PARAMETERS = {
  ENABLE_EVENT : 'enable_event', // this value to enable for old app version event, avoid crashes issue
  EVENT_CARD: 'event_card',
  SKIP_IMAGE_SECURITY_CHECK: 'is_skip_image_security_check',
  ENABLE_NEW_EVENTS: 'enable_new_events', // value to trigger to handle multiple events for new app version,
  ENABLE_GIFTS: 'enable_gifts',
  GIFT_CARDS: 'gift_cards',
  ENABLE_CHATGPT : 'enable_chatgpt',
  ENABLE_MONTHLY_PACKAGE : 'enable_monthly_package',
}


